.thanks-banner {
  background: #b7d4ff;
  padding: 176px 0 80px;

  h1 {
    font-family: var(--font-700);
    font-size: 48px;
    line-height: 53px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #34383c;
    margin: 0 0 15px;
  }
  p {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #34383c;
  }

  form {
    margin-top: 120px;
  }
}

form {
  label {
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.04em;
    color: rgba(52, 56, 60, 0.8);
  }

  .form-control {
    background: transparent !important;
    box-shadow: none !important;
    outline: none !important;
    border: 0;
    padding: 0 0 8px;
    border-bottom: 1px solid rgba(52, 56, 60, 0.8);
    border-radius: 0;
  }
}
