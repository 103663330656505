.payment-type {
    .card {background: #242D34; border-radius: 12px; color: #fff;}
    .card-body {padding: 24px 24px 26px;}

    h4 {font-family: var(--font-700); font-size: 32px; line-height: 43px; letter-spacing: -0.02em; margin: 0 0 10px;}
    p {font-weight: 400; font-size: 16px; line-height: 150%; margin: 0 0 24px;}

    .spacer {display: block; width: 100%; height: 1px; background: rgb(255, 255, 255, .5); margin: 10px 0;}    

    .price {
        font-weight: 14px; line-height: 135%; letter-spacing: -0.02em;
        span {font-size: 24px; font-family: var(--font-700);}
    }

    li {font-size: 16px; line-height: 110%; letter-spacing: -0.02em;}

    h5 {font-family: var(--font-700); font-size: 24px; line-height: 32px; letter-spacing: -0.02em;}

    .payment-method.active,
    .payment-method:hover {
        background: #04CBAA; color: #242D34;

        .icon-rounded {background: #000 !important;}
        .icon-rounded svg {color: #fff;}
    }
}