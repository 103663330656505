.contact-us-banner {
  background: #b7d4ff;
  padding: 176px 0 80px;

  h1 {
    font-family: var(--font-700);
    font-size: 48px;
    line-height: 53px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #34383c;
    margin: 0 0 15px;
  }
  p {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #34383c;
  }

  form {
    margin-top: 120px;
  }
}

form {
  label {
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.04em;
    color: rgba(52, 56, 60, 0.8);
  }

  .form-control {
    background: transparent !important;
    box-shadow: none !important;
    outline: none !important;
    border: 0;
    padding: 0 0 8px;
    border-bottom: 1px solid rgba(52, 56, 60, 0.8);
    border-radius: 0;
  }
}

.faq-section {
  background: #fafbff;
  padding: 120px;

  .title {
    font-family: var(--font-700);
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #34383c;
    margin: 0 0 80px;
  }

  .accordion {
    .accordion-button:focus,
    .accordion button {
      box-shadow: none !important;
    }

    .accordion-button {
      font-family: var(--font-700);
      font-size: 28px;
      line-height: 26px;
      letter-spacing: -0.04em;
      color: #34383c;
      box-shadow: none !important;
    }
    .accordion-button.collapsed {
      background: rgba(244, 248, 254, 1) !important;
      box-shadow: none;
    }

    p {
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.04em;
      color: #34383c;
    }
  }

  .accordion-button:not(.collapsed) {
    background: transparent;
  }
}
@media screen and (max-width: 600px) {
  .faq-section {
    padding: 10px;
  }
}
.our-offices {
  padding: 120px;
  background: #fff;

  h3 {
    font-family: var(--font-700);
    font-size: 32px;
    line-height: 35px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #34383c;
  }

  .offices-list {
    padding-left: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 120px;

    h5 {
      font-size: 32px;
      line-height: 35px;
      letter-spacing: -0.04em;
      color: #34383c;
    }
    p {
      font-size: 20px;
      line-height: 31px;
      letter-spacing: -0.04em;
      color: #34383c;
    }
  }
}
