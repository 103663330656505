@media screen and (max-width: 540px) {
  .about-us .about-banner h1 {
    // padding-right: 15%;
    font-size: 42px !important;
    line-height: 48px !important;
  }
  .about-us-feed .title {
    font-size: 30px !important;
    line-height: 38px !important;
    letter-spacing: -1px;
  }
}
.about-us {
  background: rgba(250, 251, 255, 1);
  .about-banner {
    position: relative;
    padding-top: 136px;

    &::before {
      content: "";
      background: rgba(183, 212, 255, 1);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      height: 71%;
    }

    .container {
      z-index: 1;
      position: relative;
    }

    h1 {
      font-family: var(--font-700);
      font-size: 80px;
      color: var(--gray);
      line-height: 92px;
      margin: 0 0 40px;
    }

    .image {
      height: 550px;
      width: 100%;
      border-radius: 12px;
      overflow: hidden;
    }
    .image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .our-mission {
    margin-bottom: 80px;
  }

  .our-team {
    padding: 120px 0;
    background: #fff;
    .team-members {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 64px;
    }

    .team-card .image {
      height: 300px;
      overflow: hidden;
      background: rgba(214, 218, 232, 1);
      text-align: center;
      margin: 0 0 16px;
    }
    .team-card h4 {
      margin: 0 0 8px;
      font-size: 32px;
      font-family: var(--font-500);
    }
    .team-card p {
      color: rgba(116, 123, 134, 1);
      font-size: 20px;
      line-height: 26px;
    }
  }

  .about-us-feed {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: auto;
      background: rgba(232, 240, 252, 1);
    }
  }
}

.text-feed {
  .grid-col-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 1440px) {
    .gap-80 {
      grid-gap: 80px;
    }
  }

  .image {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
  }
  .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .title {
    font-family: var(--font-700);
    font-size: 40px;
    line-height: 52px;
    letter-spacing: -0.04em;
    color: #34383c;
  }
  .desc {
    font-size: 20px;
    line-height: 31px;
    letter-spacing: -0.02em;
    color: #34383c;
  }
}
