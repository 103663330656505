.checkout-page {
    padding: 136px 0 80px;

    h2 {font-family: var(--font-700); font-size: 32px; line-height: 35px; letter-spacing: -0.04em; color: #34383C; margin: 0 0 25px;}

    h4  {font-family: var(--font-700); font-size: 24px; line-height: 31px; letter-spacing: -0.04em; color: #34383C;}

    .coupon-card {
        width: 412px;
        
        input {background: #F7F7F7; border-radius: 40px; border: 0; outline: none; box-shadow: none; font-size: 16px; width: 100%; padding: 12px 18px;}
        
        .coupon {
            position: relative;
            .btn {font-size: 14px; padding: 12px 31px; font-family: var(--font-400); position: absolute; right: 5px; top: 50%; transform: translateY(-50%);}
        }

        p {font-size: 16px; line-height: 18px; letter-spacing: -0.04em; color: #34383C;}
        p span {font-family: var(--font-700);}
    }

    .card {border: 1px solid #E4EAF2; border-radius: 12px; font-size: 18px;}
    .card-body {padding: 16px 24px;}

    label {font-size: 20px;}

    .row {margin-top: 40px;}
}