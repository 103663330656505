.home-page {
  header a {
    color: #fff;
  }
}
.dropdown-toggle.nav-link {
  color: #fff;
}
.offcanvas.offcanvas-end.show {
  background-color: #19202c;
  .offcanvas-header {
    .btn-close {
      color: #fff;
      border: 4px solid #fff;
      opacity: 1;
    }
  }
  .offcanvas-body {
    .navbar-nav {
      .nav-item {
        color: #fff !important;
      }
      .nav-item:active {
        border-bottom: 1px solid #fff !important;
      }
      .nav-item:hover {
        border-bottom: 1px solid #fff !important;
      }
      .nav-item::selection {
        border-bottom: 1px solid #fff !important;
      }

      .btn-scale {
        transition: none !important;
        transform: none !important;
      }
    }
  }
}
.slick-arrow.slick-next.slick-disabled {
  display: none;
}
.slick-arrow.slick-next {
  display: none !important;
}
.home-banner {
  position: relative;
  text-align: center;
  padding: calc(168px + 151px) 0 168px 0;
  color: #fff;

  .bg-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  h1 {
    font-family: var(--font-700);
    font-size: 72px;
    line-height: 110%;
    letter-spacing: -0.04em;
    margin: 0 0 12px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.02em;
    margin: 0 0 40px;
  }
}

.sub-heading {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #747b86;
  margin-top: 18px;
}

.benefit-section {
  .card-holder {
    margin-top: 120px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 80px;
  }
}

.order-process {
  background: #fafbff;
  .btn-primary {
    font-weight: 700;
  }
}

.order-container {
  margin: 80px 0px 0px calc((100% - 1320px) / 2);
  padding-right: 80px;
  .order-process-list {
    display: flex;
    grid-gap: 24px;
    margin-right: -80px;
  }
}
@media screen and (max-width: 500px) {
  .order-container {
    padding-right: 5px;
    margin: 10px;
  }
  .order-process {
    background: #fafbff;
    .btn-primary {
      font-weight: 600;
      padding: 10px 20px;
      margin-bottom: 10px;
    }
  }
  .home-banner {
    h1 {
      font-family: var(--font-700);
      font-size: 54px;
      line-height: 110%;
      letter-spacing: -0.04em;
      margin: 0 0 12px;
    }
  }
}
.special-offer {
  background: var(--gradient);
  padding: 80px;
}
@media only screen and (max-width: 500px) {
  .special-offer {
    padding: 5px;
  }
}

.testimonial-section {
  p {
    font-family: var(--font-400);
    font-size: 24px;
    line-height: 26px;
    margin: 0 0 24px;
  }
  p span {
    font-family: var(--font-500);
  }

  h2 {
    font-family: var(--font-500);
    font-size: 56px;
    line-height: 78px;
    letter-spacing: -0.02em;
  }

  @media screen and (min-width: 1440px) {
    h2 {
      padding-right: 15%;
    }
  }
  @media screen and (max-width: 540px) {
    h2 {
      // padding-right: 15%;
      font-size: 36px;
      line-height: 48px;
    }
  }

  .slick-arrow-icon {
    position: absolute;
    right: 0;
    bottom: -112px;
    border: 1px solid var(--gray);
    border-radius: 100%;
    &.slick-icon-prev {
      right: 66px;
    }
  }
  .slick-arrow-icon:hover,
  .slick-arrow-icon:focus {
    background-color: #dedddf;
  }

  .total-slide {
    margin-top: 80px;
    p {
      margin: 0;
    }
  }
}
