.pricing-banner {
  color: #fff;
  padding: 176px 0 80px;
  background: linear-gradient(244.73deg, #181d2b -0.74%, #1c282d 98.58%);

  h1 {
    font-family: var(--font-700);
    font-size: 48px;
    letter-spacing: -0.04em;
  }

  .paragraph {
    font-size: 20px;
    letter-spacing: 0.02em;
    color: #ffffff;
  }
}

.shipping-info {
  h4 {
    font-family: var(--font-700);
    font-size: 32px;
    line-height: 35px;
    letter-spacing: -0.04em;
    color: #34383c;
  }
  h5 {
    font-size: 24px;
    line-height: 37px;
    letter-spacing: -0.04em;
    color: #34383c;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.04em;
    color: rgba(12, 14, 18, 0.6);
  }

  .price {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.04em;
    color: rgba(12, 14, 18, 0.6);
    span {
      font-family: var(--font-700);
      font-size: 32px;
      line-height: 50px;
      letter-spacing: -0.04em;
      color: #3f7bf6;
    }
  }
}

.pricing-page {
  .grid-col-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 140px;
  }

  .slider-wrapper {
    padding: 43px;
    background: linear-gradient(236.76deg, #1bc7fc -3.96%, #31a0fc 105.15%),
      linear-gradient(236.76deg, #ea1bfc -3.96%, #9731fc 105.15%);
    border-radius: 8px;

    .shipping-card-item {
      .slick-initialized {
        display: grid;
      }

      .item {
        max-width: 300px;
        margin: auto;
        background: #ffffff;
        overflow: hidden;
        box-shadow: 0px 0px 14px rgba(62, 76, 92, 0.2);
        border-radius: 8px;

        .image {
          background: linear-gradient(
            236.76deg,
            #2db4de -3.96%,
            #31a0fc 105.15%
          );
          height: 150px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
        .image img {
          object-fit: contain;
        }

        .desc {
          padding: 16px;
        }
        .desc p {
          font-size: 12px;
          font-family: var(--font-500);
          line-height: 16px;
          letter-spacing: -0.02em;
          color: #34383c;
        }
        .desc p span {
          font-family: var(--font-700);
          font-size: 18px;
        }
        .desc h2 {
          font-family: var(--font-700);
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.02em;
          color: #34383c;
        }
      }
    }

    .slick-dots {
      bottom: 20px;
      width: 300px;
      left: 50%;
      transform: translateX(-50%);
      margin: auto;
      padding: 0 10px;
      display: flex !important;
      align-items: center;
      justify-content: flex-start;

      li {
        margin: 0;
        width: 12px;
      }
      li button {
        padding: 0;
      }

      li.slick-active button:before {
        color: var(--primary);
        opacity: 1;
      }
    }

    .card-handles {
      padding: 5px 0;
    }
  }

  .reverse {
    --primary: #9d1efc;

    .shipping-info {
      order: 2;
    }
    .shipping-info .price span {
      color: var(--primary);
    }
    .slider-wrapper {
      order: 1;
      background: linear-gradient(236.76deg, #991bfc -3.96%, #bb31fc 105.15%);
    }
    .slider-wrapper .item .image {
      background: linear-gradient(236.76deg, #7800d6 -3.96%, #9400da 105.15%);
    }
  }
}
@media screen and (max-width: 500px) {
  .graph-section {
    padding: 10px !important;
  }
}

.graph-section {
  background: #181d2b;
  text-align: center;
  padding: 80px;

  h2 {
    font-family: var(--font-700);
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -0.04em;
    color: #ffffff;
  }
}
