.sidebar {
  background-color: #181d2b;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1;
  width: 0;
  transition: all 0.3s ease-in-out;
}

.sidebar.show {
  width: 100%;
}

.sidebar .nav-link {
  padding: 10px;
  text-align: center;
}

.main-content {
  margin-left: 0;
}

@media (min-width: 768px) {
  .sidebar {
    width: 240px;
  }

  .main-content {
    margin-left: 240px;
  }
}
