.research-banner {
    background: linear-gradient(244.73deg, #181D2B -0.74%, #1C282D 98.58%), #B7D4FF; padding: 176px 0 240px; color : #fff;

    h1 {font-family: var(--font-700); font-size: 48px; line-height: 53px; text-align: center; letter-spacing: -0.04em;}
    p {font-size: 20px; margin-top: 15px; line-height: 30px; text-align: center; letter-spacing: 0.02em; color: #FFFFFF;}
}

.product-research {

    .banner-card {
        padding-bottom: 80px;
        .row {margin-top: -150px;}

        .card {background: #FFFFFF; box-shadow: 16px 24px 32px rgba(62, 76, 92, 0.08); border-radius: 12px; border: 0; height: 100%;}
        .card-body {padding: 24px;}

        h3 {font-family: var(--font-700); font-size: 24px; line-height: 32px; letter-spacing: -0.02em; color: #34383C;}
        p {font-size: 18px; line-height: 27px; letter-spacing: 0.02em; color: #34383C;}
    }
}


.research-step {
    background: #FAFBFF; padding: 80px; 

    .section-title {margin-bottom: 80px;}

    .item {
        max-width: 520px; padding-bottom: 24px; border-bottom: 1px solid rgba(36, 45, 52, .1);
        &.right {margin: auto; margin-right: 0;}
    }
    .item h3 {font-family: var(--font-500); font-size: 24px; line-height: 26px; letter-spacing: -0.02em; color: #34383C;}
    .item p {font-size: 18px; line-height: 27px; letter-spacing: 0.02em; color: #34383C;}

    .btn-primary {padding: 16px 87px;}

}