.best-seller {
  .seller-banner {
    background: #b7d4ff;
    padding: 136px 0 80px;

    h1 {
      font-family: var(--font-700);
      font-size: 64px;
      line-height: 72px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #34383c;
    }
  }

  .products {
    padding: 0 0 80px;

    .product-search {
      position: relative;
      z-index: 9;
      // width: 800px;
      margin: -33px auto 80px;
    }

    .search-icon {
      margin: 0 0 0 40px;
    }
    .clear-icon {
      margin: 0 40px 0 0;
    }
  }

  .filters {
    position: sticky;
    top: 60px;
    // width: 302px;
    border: 0;
    border-right: 1px solid #dddddd;
    padding-right: 24px;
    height: 100%;

    .accordion + .accordion {
      margin-top: 24px;
    }
    .accordion {
      border: 0;

      .accordion-item {
        border: 0;
      }
      .accordion-header {
        border: 0;
      }
      .accordion-header button {
        color: #34383c !important;
        background: transparent;
        box-shadow: none !important;
        border: 0;
        padding: 0 0 16px 0;
        font-size: 20px;
        font-family: var(--font-700);
      }

      .accordion-body {
        padding: 0;
        input {
          width: 20px;
          height: 20px;
        }
        span {
          font-size: 18px;
          line-height: 27px;
        }
      }
    }
  }

  .product-count {
    font-size: 16px;
    letter-spacing: 0.01em;
    font-family: var(--font-500);
  }

  .button-holder {
    margin-top: 15px;
  }
  .btn-outline-primary {
    font-size: 16px;
    padding: 12px 110px;
  }
}

.product-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  .image {
    width: 100%;
    height: 400px;
    border-radius: 12px;
    overflow: hidden;
  }
  .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  h5 {
    font-family: var(--font-700);
    margin-top: 14px;
    font-size: 20px;
  }
}

// product-detail-banner

.product-detail-banner {
  padding: 136px 0 40px;
  background: #b7d4ff;

  h1 {
    font-size: 32px;
    font-family: var(--font-700);
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #34383c;
  }

  .detail-images {
    display: grid;
    grid-template-columns: 1fr 456px;
    grid-gap: 24px;

    .image {
      position: relative;
      border-radius: 4px;
      overflow: hidden;
    }
    .image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .container-lg {
    padding: 0 24px;
    max-width: 1600px;
  }
}

.title-effect {
  position: relative;
  font-family: var(--font-700);
  font-size: 24px;
  line-height: 26px;
  padding-left: 14px;
  &::before {
    content: "";
    width: 6px;
    height: 28px;
    border-radius: 10px;
    background: var(--primary);
    position: absolute;
    left: 0;
    top: 0;
  }
}

.detail-product {
  padding: 0 0 80px;

  p {
    font-size: 16px;
    line-height: 25px;
  }

  .product-stats {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 24px;

    .card {
      border: 1px solid rgba(221, 221, 221, 0.6);
      border-radius: 8px;
    }

    .card-body {
      padding: 24px;
    }

    .icon-rounded {
      --size: 40px;
      margin: 0 0 16px;
      border-radius: 4px;
    }

    p {
      font-size: 14px;
      letter-spacing: 0.02em;
      color: rgba(52, 56, 60, 0.6);
    }
    h3 {
      font-family: var(--font-700);
      font-size: 28px;
      line-height: 44.8px;
      letter-spacing: 0.02em;
      color: #34383c;
    }
  }
}
