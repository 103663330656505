.order-process-card {
  border: 0.5px solid #e4eaf2;
  border-radius: 12px;
  overflow: hidden;
  max-width: 411px;
  min-width: 350px;
  margin: 0 0 80px;

  .image {
    // height: 300px;
    // padding: 2px;
  }
  .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .detail {
    padding: 24px;

    h4 {
      font-family: var(--font-700);
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.02em;
      color: #34383c;
    }

    p {
      font-size: 18px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: #34383c;
    }

    .btn-body {
      border-color: #e4eaf2;
      color: var(--primary);
      padding: 8px 30px;
      &:focus,
      &:hover {
        color: #fff;
      }
    }
  }
}
