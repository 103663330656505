:root {
    --font-400: 'Satoshi-Regular';
    --font-500: 'Satoshi-Medium';
    --font-700: 'Satoshi-Bold';
    --font-900: 'Satoshi-Black';

    --primary: rgba(63, 123, 246, 1);
    --primary-2: #EDF5FF;

    --dark: rgba(24, 29, 43, 1);

    --gray:  rgba(36, 45, 52, 1);

    --lime: rgba(4, 203, 170, 1);

    --gradient: linear-gradient(244.62deg, #181D2B -16.18%, rgba(28, 40, 45, 0.8) 107.55%);

}

h1,h2,h3,h4,h5,h6,p {margin: 0;}