.benefit-card {
    background: #FFFFFF;
    border: 0.5px solid #E4EAF2;
    border-radius: 12px;
    max-width: 411px;
    transition: all .3s ease;

    .card-body {padding: 24px;}

    .card-title {font-family: var(--font-700); font-size: 24px; line-height: 32px; letter-spacing: -0.02em; color: #34383C; margin: 0;}

    .desc {font-weight: 400; font-size: 18px; line-height: 150%; letter-spacing: 0.02em; color: #828A96;}

    .bg-light {color: var(--primary);}

    .read-more {font-family: var(--font-500); font-size: 18px;}

    &:hover {
        box-shadow: 32px 56px 80px rgba(62, 76, 92, 0.1);
        
        .bg-light {background: var(--primary) !important; color: #fff;}
    }

}
