.order-banner {
  background: linear-gradient(244.73deg, #181d2b -0.74%, #1c282d 98.58%),
    #b7d4ff;
  padding: 178px 0 80px;
  color: #fff;

  .container-sm {
    max-width: 920px;
  }

  h1 {
    font-family: var(--font-700);
    font-size: 48px;
    line-height: 53px;
    text-align: center;
    letter-spacing: -0.04em;
  }
  p {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.02em;
  }
  form {
    margin-top: 70px;
  }
}

.order-info {
  display: grid;
  gap: 120px;
  padding: 120px 0;

  .grid-col-2 {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 133px;
  }

  h4 {
    font-family: var(--font-700);
    font-size: 32px;
    line-height: 35px;
    letter-spacing: -0.04em;
    color: #34383c;
  }
  p {
    font-size: 24px;
    line-height: 37px;
    letter-spacing: -0.04em;
    color: #34383c;
  }
}
form {
  label {
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.04em;
    color: #fff;
    margin-bottom: 10px;
  }

  .form-controler {
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 40px;
    box-shadow: none !important;
    outline: none !important;

    padding: 10px;
    color: #fff !important;
  }
  .form-check-label {
    font-size: 16px;
  }
}
